import { PointAnchor } from '@masala-lib/editorial-types';
import { ElementOrigin, ScriptElement, TimesLookup } from '../llm-types';
import { DEFAULT_DROPDOWN_CLASS_NAME } from '@masala-lib/editorial/ui/completion/core';

export type ProjectDoc<D extends ProjectData = ProjectData> = {
  id?: string; // doc id, making optional because it's auto-allocated when persisted
  projectId: string;
  kind: string;
  data: D;
  timestamp?: number;
  unitId?: string; // relevant to ProjectMetadata
  // volumeId?: string;
};

export type ProjectData =
  | ProjectMetadata
  | ReferenceScript
  | Exchange
  | ImportScript
  | OverridesData
  | AddedElementsData
  | AlertSuppressions
  | ExchangeFlags
  | ElementFlags
  | Notes
  | Signoffs;

export interface BaseProjectData {
  id?: string; // will be allocated if unassigned when persited
  kind: string;
  projectId: string;
  timestamp?: number; // assigned when persisted
}

export type Step = Exchange | ReferenceScript;

export type ProjectTask = 'translation' | 'structural' | 'vocab' | 'freeform';

export type SidebarMode = 'thread' | 'exchange';

export type CreateProjectParams = {
  unitId: string;
  unitName: string; // denormalize so we don't need to load masala data
  name: string; // project name to be displayed in list view and summary panel
  task: ProjectTask;
  user: string; // alias of user who created the project
  preset?: string; // loosely typed sub-task
};

export type SynthElementFunction = (
  element: ScriptElement,
  origin: ElementOrigin,
  timestamp: number
) => ScriptElement;

export interface ProjectMetadata extends BaseProjectData {
  kind: 'PROJECT_METADATA';
  dbStructureVersion: number;
  unitId: string;
  unitName: string;
  unitTitle?: string;
  name: string; // project name to be displayed in list view and summary panel
  task: ProjectTask;
  user: string; // alias of user who created the project
  l1Code?: string;
  l2Code?: string;
  l0IsTranslation?: boolean; // indicates we should be using the translated text when working on structural content
  subtask?: string; // placeholder until other project params are flushed out
  llmOptions: LLMOptions;
  state: string;
  archived?: boolean;
  completedTimestamp?: number;
  sidebarMode?: SidebarMode; // preference between showing all exchanges or just threads
  twoColumnMergeEnabled?: boolean; // controls what the 'merge' button does
  threadCounter: number; // used to generate letter codes for exchanges

  // TODO system prompt?
  // TODO when create can config for diff llm endpoints (ala typingminds) or can switch dynamically?
}

export type Range = {
  begin: number;
  end: number;
};

export type Ranges = Range[];

export type NumberSet = Set<number>;

export type LintAlertKind =
  | 'SENTENCE_MISMATCH'
  | 'LENGTH'
  | 'MISSING_TEXT'
  | 'LOCATION_CONFLICT'
  | 'PARSE_DETAIL'
  | 'RECONCILE'
  | 'OVERLAP'
  | 'UNRECOGNIZED';

export type LintAlert = {
  kind: LintAlertKind;
  key: string;
  elementId: string;
  reference: number;
  level: 'WARNING' | 'FATAL';
  message: string;
};

export type RawResponse = {
  text: string; // possibly overridden
  overridden?: boolean;
  originalLlmText?: string; // preserve the raw llm response when overridden
  usedTokens: number; // TODO is it the total of all including response or just response part?
  llmExtra: any;
  timestamp: number;
  // for now, capturing a single caught error
  error?: string; // @jason, thoughts about a string[] or other richer typing here?
};

export type Request = {
  text: string;
  promptText: string;
  promptHash: string;
  parserKey: string; // will correlate to prompt's parser once multiple parsers are supported
  user: string; // TODO
  llmOptions: LLMOptions;
  timestamp: number;
};

export type ParsedResponse = {
  elements: ScriptElement[];
  alerts: LintAlert[];
  errors?: any; // todo typing
};

export type ResponseParserFn = (
  responseText: string,
  timestamp?: number
) => ParsedResponse;

export type ResponseParserLookup = {
  [index: string]: ResponseParserFn;
};

export interface Exchange extends BaseProjectData {
  kind: 'EXCHANGE';
  code: string; // very short code to identify the exchange. i.e. A2
  label?: string; // optional text to use instead of head of request to show in sidebar
  // TODO title: string - could use to put chapter num involved etc - has default?
  // todo: probably add some human friendly ordinal value here
  // todo: probably also add the thread depth
  priorId: string; // linked list of exchanges
  request: Request;
  referenceElements: ScriptElement[];
  rawResponse: RawResponse; // also holds the streaming partial response text
  // overrideResponseText?: string; // manual edits to be used instead of rawResponse when present
  parsedResponse: ParsedResponse;
  usedTokens: number; // TODO needed?

  pendingResponse: boolean; // true while response is still streaming back
  // pendingAbort?: boolean; // true when the user is attempting to interrupt to streamed response
  aborted?: boolean; // @jason, do you think should this be here or inside 'RawResponse'?
  requestDurationSeconds?: number; // time passed between starting and completing the llm api request

  // TODO requestPreviewData??
  // TODO responsePreviewData??
}

export interface ReferenceScript extends BaseProjectData {
  kind: 'REFERENCE_SCRIPT';
  priorId: string;
  elements: ScriptElement[];
  referenceRanges: Range[]; // je: unsure what this is for
  sectionBoundaries: number[]; // head ref for each section, plus 1 greater than last element
  sectionRanges: Range[];
  overriddenSections?: boolean; // set to true once section boundaries are tweaked. changes UI labels
  elementIdToTranslation: IdToElement; // beware, this is currently indexed by the element reference number not element id
  timesLookup: TimesLookup;
  audioUrl: string;
}

export type IdToElement = { [id: string]: ScriptElement };

// data imported back to script editor from samosa project
export interface ImportScript extends BaseProjectData {
  kind: 'IMPORT_SCRIPT';
  unitId: string;
  elements: ScriptElement[];
  referenceToId: { [index: string]: string }; // script editor element mapping data
  referenceToAnchor: { [index: string]: PointAnchor }; // need word anchors when creating structural elements
}

export type ProjectSettings = {
  showSuppressedElements: boolean;
  showSuppressedExchanges: boolean;
  showAllExchanges: boolean;
  conditionallyShowSectionsTab: boolean;
  showAdvancedScriptOptions: boolean;
  task: ProjectTask;
  useL1: boolean;
  overwriteMasalaData: boolean;
  defaultName: string;
};

export type ProjectDocIds = {
  editsDocId?: string;
  addedElementsDocId?: string;
  elementFlagsDocId?: string;
  exchangeFlagsDocId?: string;
  suppressedAlertsDocId?: string;
  notesDocId?: string;
  signoffsDocId?: string;
};

export interface ProjectModel {
  projectMetadata: ProjectMetadata;
  referenceScript: ReferenceScript;
  stepMap: Map<string, Step>;
  docIds: ProjectDocIds;
  editLookup: ElementOverrideLookup; // TODO name?
  addedElementsLookup: AddedElementsLookup; // TODO name?
  elementFlags: FlagsData;
  exchangeFlags: FlagsData;
  suppressedAlerts: SuppressionsData;
  signoffs: SignoffsData;
  notes: NotesData;
  importScript: ImportScript;
}

export type StepLookup = {
  [index: string]: Step;
};

export type StepListLookup = {
  [index: string]: Step[];
};

export type Edit = false | ScriptElement;

export type ElementOverrideLookup = {
  [index: string]: Edit;
};

export type AddedElementsLookup = ElementOverrideLookup;

// TODO Data added to name not consistent?
export interface OverridesData extends BaseProjectData {
  kind: 'OVERRIDE_EDITS';
  overrideLookup: ElementOverrideLookup;
}

export interface AddedElementsData extends BaseProjectData {
  kind: 'ADDED_ELEMENTS';
  addedElementsLookup: AddedElementsLookup;
}

export type Signoff = {
  elementId: string;
  timestamp: number;
};

export type SignoffsData = {
  [index: string]: Signoff;
};

export interface Signoffs extends BaseProjectData {
  kind: 'SIGNOFFS';
  signoffs: SignoffsData;
}

export type NotesData = {
  [index: string]: string;
};

export interface Notes extends BaseProjectData {
  kind: 'NOTES';
  notes: NotesData;
}

export const MASALA_FLAG = 1;
export const SAMOSA_FLAG = 1 << 1;
export const SUPPRESSED = 1 << 2;
export const ARCHIVED = 1 << 3;

export type FlagsData = {
  [index: string]: number;
};

export interface ElementFlags extends BaseProjectData {
  kind: 'ELEMENT_FLAGS';
  flags: FlagsData;
}

export interface ExchangeFlags extends BaseProjectData {
  kind: 'EXCHANGE_FLAGS';
  flags: FlagsData;
}

export type SuppressionsData = {
  [index: string]: boolean;
};

export interface AlertSuppressions extends BaseProjectData {
  kind: 'ALERT_SUPPRESSIONS';
  suppressions: SuppressionsData;
}

export type LLMMessage = {
  role: 'user' | 'assistant' | 'system';
  content: string;
};

export type LLMOptions = {
  model: string;
  temperature: number; // 0-1 (0 = most, 1 = most creative/random)
  systemPrompt: string;
  streamingDisabled?: boolean; // currently needed for claude
  parsingDisabled?: boolean; // omit from merge (not really an llm option, but convenient to store here)
};

// @jason / @armando what are your opinions between the `LlmModelChoice` vs `LLMModelChoice` naming convention?
// so far our code base is inconsistent and i'd like make sure we have a clear convention to use moving forward.
export type LlmModelChoice = {
  key: string;
  // @armando, not sure if you want a full url or something else here,
  // so i'll let you fill in and please feel free to rename
  icon: string;
  name?: string; // @jason do you to configure prettier display names or always just display the raw key strings?
};

export interface MessageEditor {
  getCurrentText(): string;
  clear(): void;
}

export interface ModalTextEditor {
  run(text: string): Promise<boolean>;
  text: string;
}

export interface ModalMergeScriptTouchupEditor {
  run(model: ProjectModel): Promise<boolean>;
  edits: ElementOverrideLookup;
  alertSuppressions: SuppressionsData;
  elementSuppressions: SuppressionsData;
}

export interface SectionInfo {
  title: string;
  steps: Step[];
  missingCount: number;
  conflictCount: number;
  completeButNotSignedOffCount: number; // constructed this to be exclusive of missing and conflict
  samosaFlaggedCount: number;
}

export interface SectionsInfo {
  // hasSectionedResponses: boolean; // not sure if this is useful
  hasSections: boolean; // true when there are sections regardless if there are matching exchanges
  nonFitting: Step[];
  sections: SectionInfo[];
}

//
// prompt management
//

// saved and sharable prompt templates
export interface Prompt {
  id?: string; // will be allocated if unassigned when persited
  task: ProjectTask; // task flavor for which this prompt is relevant
  slug: string;
  title: string;
  description: string;
  // unitId currently
  scope?: string;
  // combo of l2:l1
  languagesKey?: string;
  text: string;
  parserKey: string;
  position?: number; // primary sort order
  archived?: boolean;
  // status: string; // placeholder
  timestamp?: number; // assigned when persisted, secondary sort order (desc)
}

export interface ScriptOptions {
  chapters?: boolean;
  passages?: boolean;
  speakers?: boolean;
  sentences?: boolean;
  numbers?: boolean;
  translations?: boolean; // L1 chapter, passage, sentence output
  sub_translations?: boolean;
}

export type ScriptOptionsKey = keyof ScriptOptions;
// is there a better way?
export const scriptOptionsKeys: ScriptOptionsKey[] = [
  'chapters',
  'passages',
  'speakers',
  'sentences',
  'numbers',
  // 'sub_translations',
];
