import { styled } from '@naan/stitches.config';
import { observer } from 'mobx-react';
import { QuickButton } from './quick-button';
import { showInjectDialog } from './inject-script-modal';
import { ArrowDownIcon } from '@naan/icons/arrow-down-icon';
import { GearIcon } from '@naan/icons/gear-icon';
import { PiFilmScript } from 'react-icons/pi';
import { showAutomationDialog } from './automation-modal';
import { showPromptPicker } from '../prompts/prompt-picker-modal';
import { useSamosaModel } from 'samosa/models/samosa-model-handle';

export const QuickActions = observer(() => {
  const model = useSamosaModel();
  return (
    <>
      <QuickButton
        leftIcon={<ArrowDownIcon />}
        label={'Prompts'}
        onClick={showPromptPicker}
        shortcut="ctrl+p"
      />
      <QuickButton
        leftIcon={<PiFilmScript />}
        label={'Script'}
        onClick={showInjectDialog}
        shortcut="ctrl+s"
      />
      <QuickButton
        leftIcon={<GearIcon />}
        label={'Automations'}
        onClick={showAutomationDialog}
        shortcut="ctrl+u"
      />
      {/* {model.showSectionManager ? (
              <QuickButton
                leftIcon={<AllStoriesIcon />}
                label={'Sections'}
                onClick={showSectionsDialog}
              />
            ) : null} */}
    </>
  );
});
